import * as React from "react";
import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";
import Container from "../components/container";
import Layout from "../components/layout";
import enjin_3_mp4 from "../assets/enjin_3_test2.mp4";
import enjin_3_webm from "../assets/enjin_3_test2.webm";
import enjin_3_poster from "../assets/enjin_3_test2_poster.jpg";
import Handbuch from "../assets/i-engineBenutzerhandbuch_v21.4.pdf";
import IheIntegrationsStatement from "../assets/health-engine-IHEIntegrationsStatement_10.pdf";
import DicomConformanceStatement from "../assets/healthengine-DICOMConformanceStatement_03.pdf";
import Hl7ConformanceStatementD from "../assets/healthengine-HL7ConformanceStatement-Deutsch_04.pdf";
import Hl7ConformanceStatementE from "../assets/healthengine-HL7ConformanceStatement-English_04.pdf";
import pdfIconPurple from "../assets/pdf_icon_purple.svg";
import ReactMarkdown from "react-markdown";
import {
  videoBackgroundColorHack5,
  flexGrid,
  sectionVideo,
  sectionText,
  sectionTextNegativeMarginTop,
  sectionPadding,
  containerPaddingLeft,
  containerPaddingRight,
  orderReverse,
  marginTopMobile,
  containerPadding,
  eyesContainer,
  column,
  buttonGroup,
} from "./page.module.scss";

const TheIEngineersPage = ({ data }) => {
  const videoRef = React.useRef(null);
  const [showFirstImage, setShowFirstImage] = React.useState(true);

  const [ref] = useInView({
    triggerOnce: false,
    rootMargin: "0px 0px",
    onChange: (inView) => {
      if (inView) {
        videoRef?.current && videoRef.current.play();
      } else {
        videoRef?.current && videoRef.current.pause();
      }
    },
  });

  React.useEffect(() => {
    const timing = showFirstImage ? 2000 : 500;
    const intervalId = setInterval(() => {
      setShowFirstImage(!showFirstImage);
    }, timing);
    return () => clearInterval(intervalId);
  }, [showFirstImage]);

  return (
    <Layout colorScheme="support" pageTitle="Support">
      <main>
        <section
          className={`${"text-orange"} ${"background-rose-pink"}`}
          style={{ position: "relative" }}
          ref={ref}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            poster={enjin_3_poster}
            className={videoBackgroundColorHack5}
            ref={videoRef}
          >
            <source src={enjin_3_mp4} type="video/mp4" />
            <source src={enjin_3_webm} type="video/webm" />
          </video>
          <Container noPadding={true}>
            <div className={flexGrid}>
              <div className={column}>
                <div className={sectionVideo}></div>
              </div>

              <div
                className={`${column} ${sectionText} ${containerPadding} ${sectionTextNegativeMarginTop}`}
              >
                <h1>Immer für Sie da.</h1>
                <p className="text-large">
                  Willkommen auf der TIE Support-Seite. Wie können wir Ihnen
                  weiterhelfen?
                </p>
              </div>
            </div>
          </Container>
        </section>

        <section
        id="help-center"
          className={`${"background-orange"} ${"text-rose-pink"} ${sectionPadding}`}
        >
          <Container>
            <div className={`${flexGrid}`}>
              <div
                className={`${column} ${sectionText} ${containerPaddingRight}`}
              >
                <h1 style={{ marginBottom: 16 }}>Help Center</h1>
                <p className="text-large">
                  Login für das the i-engineers Ticketing-System. Oder nehmen
                  Sie sich das i-engine Benutzerhandbuch zur Hilfe.
                </p>
                <div className={buttonGroup}>
                  <a
                    href="https://jira.tie.ch/servicedesk/customer/portal/22"
                    target="_blank"
                    rel="noreferrer"
                    className="button rose-pink text-orange"
                  >
                    Login
                  </a>
                  <a
                    href={Handbuch}
                    target="_blank"
                    rel="noreferrer"
                    className="button rose-pink text-orange"
                  >
                    Download
                  </a>
                </div>
              </div>
              <div className={column}>
                <div
                  className={`${eyesContainer} ${
                    showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_WEST.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
                <div
                  className={`${eyesContainer} ${
                    !showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_NORTH.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section
        id="release-notes"
          className={`${"background-spring-green"} ${"text-purple"} ${sectionPadding}`}
        >
          <Container>
            <div className={`${flexGrid} ${orderReverse}`}>
              <div
                className={`${column} ${sectionText} ${containerPaddingLeft}`}
              >
                <h1 style={{ marginBottom: 16 }}>i-engine Neue&shy;rungen</h1>
                <p className="text-large">
                  Download der Release Notes als PDF.
                </p>
                <div className={buttonGroup}>
                  <Link
                    to="release-notes"
                    className="button purple text-spring-green"
                  >
                    Alle Release Notes
                  </Link>
                </div>
              </div>
              <div
                className={`${column} ${sectionText} ${containerPaddingRight} ${marginTopMobile}`}
              >
                {!!data.releaseNotes?.nodes &&
                  data.releaseNotes.nodes.map((node) => (
                    <div key={node.id}>
                      <a
                        href={node.childMdx.frontmatter.pdf.publicURL}
                        className="text-purple"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h2
                          className="text-large"
                          style={{
                            marginBottom: 0,
                            display: "inline-block",
                          }}
                        >
                          <img
                            style={{
                              marginRight: 16,
                              width: 30,
                              height: "auto",
                            }}
                            src={pdfIconPurple}
                            alt=""
                          />
                          Release Notes v{node.name}&nbsp;&gt;
                        </h2>
                      </a>
                      <div style={{ marginTop: 16 }}>
                        <ReactMarkdown>{node.childMdx.body}</ReactMarkdown>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Container>
        </section>

        <section
        id="conformance-statements"
          className={`${"background-sun-yellow"} ${"text-purple"} ${sectionPadding}`}
        >
          <Container>
            <div className={`${flexGrid}`}>
              <div
                className={`${column} ${sectionText} ${containerPaddingRight}`}
              >
                <h1 style={{ marginBottom: 16 }}>
                  Confor&shy;mance <br />
                  State&shy;ments
                </h1>
                <p className="text-large">
                  Download der Conformance Statements als PDF.
                </p>
              </div>
              <div
                className={`${column} ${sectionText} ${containerPaddingLeft} ${marginTopMobile}`}
              >
                <div style={{ marginBottom: 16, display: "flex" }}>
                  <img
                    style={{ paddingRight: 16 }}
                    src={pdfIconPurple}
                    alt=""
                  />
                  <a
                    href={IheIntegrationsStatement}
                    className="text-purple text-larger"
                    target="_blank"
                    rel="noreferrer"
                  >
                    IHE Integration Statement
                  </a>
                </div>

                <div style={{ marginBottom: 16, display: "flex" }}>
                  <img
                    style={{ paddingRight: 16 }}
                    src={pdfIconPurple}
                    alt=""
                  />
                  <a
                    href={DicomConformanceStatement}
                    className="text-purple text-larger"
                    target="_blank"
                    rel="noreferrer"
                  >
                    DICOM Conformance Statement
                  </a>
                </div>

                <div style={{ marginBottom: 16, display: "flex" }}>
                  <img
                    style={{ paddingRight: 16 }}
                    src={pdfIconPurple}
                    alt=""
                  />
                  <a
                    href={Hl7ConformanceStatementD}
                    className="text-purple text-larger"
                    target="_blank"
                    rel="noreferrer"
                  >
                    HL7 Conformance Statement (D)
                  </a>
                </div>

                <div style={{ marginBottom: 16, display: "flex" }}>
                  <img
                    style={{ paddingRight: 16 }}
                    src={pdfIconPurple}
                    alt=""
                  />
                  <a
                    href={Hl7ConformanceStatementE}
                    className="text-purple text-larger"
                    target="_blank"
                    rel="noreferrer"
                  >
                    HL7 Conformance Statement (E)
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section
        id="browser-support"
          className={`${"background-purple"} ${"text-spring-green"} ${sectionPadding}`}
        >
          <Container>
            <div className={`${flexGrid} ${orderReverse}`}>
              <div
                className={`${column} ${sectionText} ${containerPaddingLeft}`}
              >
                <h1 style={{ marginBottom: 16 }}>Browser Support</h1>
                <p className="text-large">
                  Um die beste Nutzererfahrung sicherzustellen, empfehlen wir
                  die Verwendung der aktuellsten Version des jeweiligen
                  Browsers.
                </p>
              </div>
              <div
                className={`${column} ${sectionText} ${containerPaddingRight} ${marginTopMobile}`}
              >
                <p className="text-large">Wir unterstützen folgende Browser:</p>

                <ul>
                  <li>
                    <p className="text-large" style={{ marginBottom: 0 }}>
                      Chrome
                    </p>
                  </li>
                  <li>
                    <p className="text-large" style={{ marginBottom: 0 }}>
                      Microsoft Edge
                    </p>
                  </li>
                  <li>
                    <p className="text-large" style={{ marginBottom: 0 }}>
                      Firefox
                    </p>
                  </li>
                  <li>
                    <p className="text-large" style={{ marginBottom: 0 }}>
                      Safari
                    </p>
                  </li>
                  <li>
                    <p className="text-large" style={{ marginBottom: 0 }}>
                      und weitere HTML5 kompatible Browser
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </section>

        <section
        id="i-engine-gesamtdokumentation"
          className={`${"background-sky-blue"} ${"text-blue"} ${sectionPadding}`}
        >
          <Container>
            <div className={flexGrid}>
              <div
                className={`${column} ${sectionText} ${containerPaddingRight}`}
              >
                <h1>i-engine Gesamt&shy;doku&shy;men&shy;tation</h1>
                <p className="text-large">
                  Fragen Sie den Project Engineer Ihres Vertrauens.
                </p>
                <div className={buttonGroup}>
                  <Link
                    to="/the-i-engineers#kontakt"
                    className="button text-sky-blue"
                  >
                    Kontakt
                  </Link>
                </div>
              </div>
              <div className={column}>
                <div
                  className={`${eyesContainer} ${
                    showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_NORTHWEST.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
                <div
                  className={`${eyesContainer} ${
                    !showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_STRAIGHT.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>
            </div>
          </Container>
        </section>

        <section
        id="sla"
          className={`${"background-spring-green"} ${"text-blue"} ${sectionPadding}`}
        >
          <Container>
            <div className={flexGrid}>
              <div className={column}>
                <div
                  className={`${eyesContainer} ${
                    showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_NORTHEAST.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
                <div
                  className={`${eyesContainer} ${
                    !showFirstImage ? "d-block" : "d-none"
                  }`}
                >
                  <StaticImage
                    src={`../assets/TIE_EYE-CON_SOUTHEAST.png`}
                    alt="eyes"
                    placeholder="none"
                    layout="constrained"
                    width={160}
                    height={160}
                    formats={["auto", "webp", "avif"]}
                  />
                </div>
              </div>
              <div
                className={`${column} ${sectionText} ${containerPaddingLeft}`}
              >
                <h1>Für ein gesundes System</h1>
                <p className="text-large">
                  Bei Problemen meldet sich Ihr System selbstständig bei uns. {" "}
                  <span className="underline">
                   
                  7x24 Support
                  </span>
                  ,{" "}unsere neue SLA Erweiterung für Sie.
                </p>
                <div className={buttonGroup}>
                  <Link
                    to="sla"
                    className="button blue text-spring-green"
                  >
                    Weitere Infos
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </section>

      </main>
    </Layout>
  );
};

export const query = graphql`
  {
    releaseNotes: allFile(
      filter: {
        sourceInstanceName: { eq: "release-notes" }
        extension: { eq: "mdx" }
      }
      sort: { order: DESC, fields: name }
      limit: 1
    ) {
      nodes {
        childMdx {
          frontmatter {
            pdf {
              publicURL
            }
          }
          body
        }
        id
        name
      }
    }
  }
`;

export default TheIEngineersPage;
